import { ChatData } from '../types/ChatData';
import { ChatDataStatus } from '../types/ChatData';
import { ChatDataType } from '../types/ChatData';

interface Conversation {
    prompt: string,
    completion: string
}

function countFourCharChunks(input: string): number {
    const chunkSize = 4;
    let chunkCount = 0;
  
    for (let i = 0; i < input.length; i += chunkSize) {
      if (i + chunkSize <= input.length) {
        chunkCount++;
      }
    }
  
    return chunkCount;
}

const TOKEN_LIMIT = 2000

function getFilterePairedConversation(chatData: ChatData[]): Conversation[]  {
    const filtered = chatData.filter(o => o.status === ChatDataStatus.COMPLETE || o.status === ChatDataStatus.TESTDATA)
    let data: Conversation[] = []
    for (let i = 0; i < filtered.length; i += 2) {
        if (filtered[i].type === ChatDataType.PROMPT && filtered[i + 1].type === ChatDataType.COMPLETION)
            data.push({
                prompt: filtered[i].value || '',
                completion: filtered[i + 1].value || '',
            })
    }
    return data
}

function getLengthLimitedConversation(conversation: Conversation[]) {
    let tokenCount = 0
    let limitedConvesation: Conversation[] = []

    while (tokenCount <= TOKEN_LIMIT) {
        const item = conversation.pop()
        if (!item) break
        const tokensRequired = countFourCharChunks(item.prompt) + countFourCharChunks(item.completion)
        if (tokenCount + tokensRequired > TOKEN_LIMIT) break;
        tokenCount += tokensRequired
        limitedConvesation.unshift(item)
    }
    
    return limitedConvesation
}

export function makeAPIMessages(chatData: ChatData[]) {
    const conversation = getFilterePairedConversation(chatData)
    return getLengthLimitedConversation(conversation)
}