import Constants, { ExecutionEnvironment } from 'expo-constants';
import { Platform } from 'react-native';

const isExpoGo = Constants.executionEnvironment === ExecutionEnvironment.StoreClient;

let FirebaseAnalytics: any | undefined;

// Create a fallback for Expo Go
if (!isExpoGo) {
    // Conditionally require this module to prevent Metro from throwing warnings.
    if (Platform.OS === 'web') {
        FirebaseAnalytics = require('./WebAnalytics').default;
    } else {
        FirebaseAnalytics = require('@react-native-firebase/analytics');
    }
}

export enum AnalyticsEvent {
    CLEAR_HISTORY = "clear_history",
    WEB_MAIN_SCREEN_VIEW = 'web_main_screen_view',
    TEXT_MESSAGE_SENT = 'text_message_sent',
    VOICE_MESSAGE_SENT = 'voice_messsage_sent',
    TTS_TOGGLED_ON = 'tts_toggled_on',
    TTS_TOGGLED_OFF = 'tts_toggled_off'
}

export function logEvent(event: AnalyticsEvent) {
    if (!FirebaseAnalytics) return

    switch (Platform.OS) {
        case 'web':
            return FirebaseAnalytics.logEvent(FirebaseAnalytics.analytics, event)
        default:
            return FirebaseAnalytics.default().logEvent(event).catch((err: any) => console.error('FirebaseAnalytics.default().logEvent(event)', err))
    }
}

export function AnalyticsComponent() {
    switch (Platform.OS) {
        case 'web':
            return <></>
        default: return <></>
    }
}