import * as UUID from "../../util/UUID"
import { Platform } from "react-native"

//const TTS_API = 'https://text-machina-api.ngrok.app/tts'
const TTS_API = 'https://text-machina-api.herokuapp.com/tts'

export async function post(text: string) {
    const deviceId = await UUID.getDeviceUUID()

    const headers: any = {
        'Content-Type': 'application/json',
        'x-device-id': deviceId,
        'application-type': Platform.OS
    }

    const response = await fetch(TTS_API, {
        method: 'POST',
        headers,
        body: JSON.stringify({ text })
    })

    return response
}