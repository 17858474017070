import { Voice } from 'expo-speech';
import React, { useEffect, useState } from 'react'
import {
  FlatList,
  Pressable,
  Text,
  View,
  StyleSheet,
  ListRenderItemInfo,
  SectionList,
  SectionListData,
  DefaultSectionT,
  Platform
} from 'react-native';
import {Slider as WebSlider} from '@miblanchard/react-native-slider';
import Slider from '@react-native-community/slider';

import * as HapticFeedback from '../util/HapticFeedback'
import Color from '../util/Color'
import { SUPPORTED_LOCALES } from '../util/TTSHandler';

export interface MenuProps {
    selectedVoice: Voice | undefined
    availableVoices: Voice[]
    selectVoice: (voice: Voice) => void
    clearHistory: () => void
    setVolume: (volume: number) => void
    setPitch: (pitch: number) => void
    setRate: (rate: number) => void
    volume: number,
    pitch: number,
    rate: number
}

interface VoiceOptions {
    [language: string]: Voice[]
}

interface VoiceOptionsItem {
    type: 'voiceOption',
    voiceOptionType: 'voice' | 'language',
    data: string | Voice
}

interface SliderType {
    type: 'slider',
    sliderType: 'volume' | 'pitch' | 'rate'
}

function MenuHeader(props: { text: string }) {
    return <View 
        style={[
            styles.headerStyle, 
            ['Volume', 'Choose Personality'].includes(props.text) ? { marginTop: 24 } : { marginTop : 12}, 
            ['Choose Personality'].includes(props.text) ? { marginBottom: 8 } : {}
        ]}
    >
        <Text style={styles.headerText}>{props.text}</Text>
    </View>
}

const BaseMenuData: { title: string, data: any[] }[] = [
    {
        title: 'Volume',
        data: [{type: 'slider', sliderType: 'volume'}]
    },
    {
        title: 'Speed',
        data: [{type: 'slider', sliderType: 'rate'}]
    },
    {
        title: 'Pitch',
        data: [{type: 'slider', sliderType: 'pitch'}]
    },
]

function languageFromLocale(locale:string) {
    return SUPPORTED_LOCALES[locale] || locale
}

function Menu(props: MenuProps) {
    const [voiceOptions, setVoiceOptions] = useState<VoiceOptions>({})
    const [voiceOptionsItems, setVoiceOptionsItems] = useState<VoiceOptionsItem[]>([])
    const [sliderPadding, setSliderPadding] = useState<24 | 22>(24)

    useEffect(() => {
        let voicesByLanguage: { [language: string]: Voice[] } = {}

        for (let voice of props.availableVoices) {
            const language = voice.language
            if (voicesByLanguage[language]) {
                let updatedVoices = voicesByLanguage[language]
                    .concat(voice)
                    .sort((a: Voice, b: Voice) => a.name.localeCompare(b.name))
                voicesByLanguage[language] = updatedVoices
            } else {
                voicesByLanguage[language] = [voice]
            }
        }

        setVoiceOptions(voicesByLanguage)
    }, [props.availableVoices])

    useEffect(() => {
        let options: VoiceOptionsItem[] = []

        // TODO - Bring default to the top on each platform (Samantha?)

        const keys = Object.keys(voiceOptions).sort((a: string, b: string) => {
            if (a === 'en-US' || (a.includes('en-') && !b.includes('en-'))) {
                return -1
            } else if (b === 'en-US' || (!a.includes('en-') && b.includes('en-'))) {
                return 1
            } else {
                return a.localeCompare(b)
            }
        })

        for (let key of keys) {
            options.push({type: 'voiceOption', voiceOptionType: 'language', data: key})
            for (let voice of voiceOptions[key]) {
                options.push({type: 'voiceOption', voiceOptionType: 'voice', data: voice})
            }
        }

        setVoiceOptionsItems(options)
    }, [voiceOptions])

    // For some reason web slider target is off without resizing it
    // this lil jiggle fixes its
    useEffect(() => {
        setSliderPadding(22)
        setTimeout(() => {
            setSliderPadding(24)
        }, 10)
    }, [voiceOptionsItems])
    useEffect(() => {
        setSliderPadding(22)
        setTimeout(() => {
            setSliderPadding(24)
        }, 10)
    }, [])

    function getSliderValue(sliderType: 'volume' | 'pitch' | 'rate') {
        switch (sliderType) {
            case 'volume': return props.volume;
            case 'pitch': return props.pitch;
            case 'rate': return props.rate;
        }
    }

    function getSliderOnValueChange(value: number[], sliderType: 'volume' | 'pitch' | 'rate') {
        HapticFeedback.lightFeedback()
        switch (sliderType) {
            case 'volume': return props.setVolume(value[0]);
            case 'pitch': return props.setPitch(value[0]);
            case 'rate': return props.setRate(value[0]);
        }
    }

    const renderItem = ({item, index}: ListRenderItemInfo<VoiceOptionsItem | SliderType>) => {
        switch (item.type) {
            case 'voiceOption':
                switch (item.voiceOptionType) {
                    case 'language':
                        return <View style={styles.languageHeaderView}>
                            <Text style={styles.languageHeaderText}>
                                {languageFromLocale(item.data as string)}
                            </Text>
                        </View>
                    case 'voice':
                        return <View>
                            <Pressable 
                                onPress={() => props.selectVoice(item.data as Voice)}
                                style={[
                                    styles.voiceButtonView
                                ]}
                            >
                                <Text style={[
                                    styles.voiceButtonText, 
                                    (item.data as Voice).identifier === props.selectedVoice?.identifier ? {color: Color.green} : {}
                                ]}>
                                    {(item.data as Voice).name.split(' (')[0]} {(item.data as Voice).identifier === props.selectedVoice?.identifier ? '✅' : ''}
                                </Text>
                            </Pressable>
                        </View>
                }
            case 'slider':
                return <View
                    style={{flex: 1, height: Platform.OS === 'android' ? 32 : 24, paddingRight: 24, paddingLeft: 24}}
                >
                    {
                        Platform.OS === 'web' ? 
                        <WebSlider
                            maximumValue={item.sliderType === 'volume' ? 1 : 2}
                            minimumValue={0}
                            minimumTrackTintColor={Color.green}
                            thumbTintColor={Color.green}
                            maximumTrackTintColor={'#D9D9D9'}
                            onValueChange={(value) => { getSliderOnValueChange(value, item.sliderType)}}
                            value={getSliderValue(item.sliderType)}
                        /> : 
                        <Slider
                            style={Platform.OS === 'android' ? {height: 32} : {}}
                            maximumValue={item.sliderType === 'volume' ? 1 : 2}
                            minimumValue={0}
                            minimumTrackTintColor={Color.green}
                            thumbTintColor={Color.green}
                            maximumTrackTintColor={'#D9D9D9'}
                            step={item.sliderType === 'volume' ? .05 : .1}
                            onValueChange={() => HapticFeedback.minorFeedback()}
                            onSlidingComplete={(value) => { getSliderOnValueChange([value], item.sliderType)}}
                            value={getSliderValue(item.sliderType)}
                        />
                    }
                </View>
        }
    }

    const renderSectionHeader = (info: { section: SectionListData<any, DefaultSectionT>}) => {
        return <MenuHeader text={info.section.title}/>
    }

    const keyExtractor = (_: any, index: number) => `menu-item-${index}`

    return (
        <View style={{ flexGrow: 1 }}>
            <SectionList
                style={[styles.listStyle]}
                keyExtractor={keyExtractor}
                sections={BaseMenuData.concat({ title: 'Choose Personality', data: voiceOptionsItems })}
                renderItem={renderItem}
                renderSectionHeader={renderSectionHeader}
                stickySectionHeadersEnabled={false}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    listStyle: {
        height: '100%', 
        width: '100%',
        position: 'absolute', 
        top: 0, 
        left: 0
    },
    headerStyle: {
        height: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        paddingRight: 24,
        paddingLeft: 24
    },
    headerText: {
        fontSize: 16,
        fontWeight: '700'
    },
    languageHeaderView: {
        backgroundColor: '#F2F2F2',
        width: '100%',
        justifyContent: 'center',
        height: 32
    },
    languageHeaderText: {
        fontSize: 12,
        fontWeight: '600',
        color: 'rgba(0,0,0,0.25)',
        paddingLeft: 24
    },
    voiceButtonView: {
        height: 50,
        justifyContent: 'center',
    },
    voiceButtonText: {
        fontSize: 16,
        fontWeight: '600',
        paddingLeft: 24
    },
    menuPressable: {
        width: '100%',
        height: 50,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default Menu