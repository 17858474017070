export enum ChatDataType {
    PROMPT, COMPLETION
}

export enum ChatDataStatus {
    ACTIVE, COMPLETE, FAILED, TESTDATA
}

export type ChatData = {
    type: ChatDataType,
    value: string | undefined,
    status: ChatDataStatus,
    uuid: string,
    createdAt: Date
};