import React from 'react'
import {
    Platform,
  Pressable,
  StyleSheet,
  Text,
  useColorScheme,
  View
} from 'react-native';

// Internal Imports

import { BetterExpoImage } from './BetterImage';
import * as HapticFeedback from '../util/HapticFeedback'

type ChatHeaderProps = {
    ttsOn: boolean,
    menuOpen: boolean,
    clearHistory: () => void
    toggleTTS: () => void,
    toggleMenu: () => void
}

const ttsOnImage = require(`../assets/img/icn_jackchat_tts_on.svg`)
const ttsOffImage = require(`../assets/img/icn_jackchat_tts_off.svg`)

function ChatHeader(props: ChatHeaderProps): JSX.Element {
    return (
        <View 
            style={styles.headerContainer}
        >
            <View
                style={[styles.headerButtonContainer, {justifyContent: 'flex-start'}]}
            >
                <Pressable 
                    style={[styles.headerPressable]}
                    onPress={() => {
                        HapticFeedback.lightFeedback()
                        props.toggleMenu()
                    }}
                >
                    {
                        props.menuOpen ?
                        <BetterExpoImage 
                            style={[styles.menuCloseImage]} 
                            key='header-close-menu-button'
                            contentFit='contain'
                            source={require(`../assets/img/icn_jackchat_menu_close.svg`)} 
                        /> :
                        <BetterExpoImage 
                            style={[styles.menuOpenImage]} 
                            key='header-open-menu-button'
                            contentFit='contain'
                            source={require(`../assets/img/icn_jackchat_menu_open.svg`)} 
                        />
                    }
                </Pressable>
            </View>
            <View
                style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
            >
                {
                    props.menuOpen ?
                    <Text
                        style={{fontSize: 20, fontWeight: '700'}}
                    >
                        Voice Settings
                    </Text> :
                    [
                        <BetterExpoImage
                            style={{height:18, width: 115}}
                            contentFit='contain'
                            key='header-wordmark'
                            source={require('../assets/img/icn_jackchat_wordmark.svg')}
                        />,
                        <BetterExpoImage
                            style={{height:9, width: 112, marginTop: 12}}
                            contentFit='contain'
                            key='header-web-connected'
                            source={require('../assets/img/icn_jackchat_web_connected.svg')}
                        />
                    ]
                }
            </View>
            <View
                style={[styles.headerButtonContainer, {justifyContent: 'flex-end'}]}
            >
                {
                    props.menuOpen ? 
                    <></> :
                    [
                        <Pressable 
                            style={[styles.headerPressable]}
                            key='header-toggle-tts-button'
                            onPress={() => {
                                HapticFeedback.lightFeedback()
                                props.toggleTTS()
                            }}
                        >
                            <BetterExpoImage 
                                style={[styles.ttsImage]} 
                                contentFit='contain'
                                source={props.ttsOn ? ttsOnImage : ttsOffImage} 
                            />
                        </Pressable>,
                        <Pressable 
                            style={[styles.headerPressable]}
                            key='header-clear-history-button'
                            onPress={() => {
                                HapticFeedback.lightFeedback()
                                props.clearHistory()
                            }}
                        >
                            <BetterExpoImage 
                                style={[styles.clearHistoryImage]} 
                                contentFit='contain'
                                source={require(`../assets/img/icn_jackchat_clear_history.svg`)} 
                            />
                        </Pressable>
                    ]
                }
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    headerContainer: {
        display: 'flex',
        height: 70, 
        alignItems: 'center',
        flexDirection: 'row',
        paddingRight: 12,
        paddingLeft: 12
    },
    headerButtonContainer: {
        flexGrow: 1, 
        flexShrink: 0,
        flex: 1,
        display: 'flex', 
        height: '100%',
        flexDirection: 'row', 
        alignItems: 'center'
    },
    bufferView: {
        width: 52,
        height: 40
    },
    headerPressable: {
        width: 50,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerButtonText: {
        color: 'white',
        fontSize: 14,
        fontWeight: '800'
    },
    menuOpenImage: {
        height: 32,
        width: 32
    },
    menuCloseImage: {
        height: 32,
        width: 32
    },
    ttsImage: {
        height: 42,
        width: 42
    },
    clearHistoryImage: {
        height: 42,
        width: 42
    }
});

export default ChatHeader;
