import React, {useEffect} from 'react'
import {
    SafeAreaProvider,
} from 'react-native-safe-area-context';
import * as ScreenOrientation from 'expo-screen-orientation';
import { RootSiblingParent } from 'react-native-root-siblings';
import { AnalyticsComponent } from './util/Analytics';

// Internal imports

import Home from './Home';
import { Platform } from 'react-native';

export type ChatResponseData = {
  prompt: string;
  completion: string | undefined;
};

function App(): JSX.Element {
    useEffect(() => {
        if (Platform.OS !== 'web')
            ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
    }, [])

    return (
        <SafeAreaProvider>
            <RootSiblingParent>
                <Home/>
            </RootSiblingParent>
            <AnalyticsComponent/>
        </SafeAreaProvider>
    );
}

export default App;