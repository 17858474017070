import { useEffect, useState, useRef } from 'react'
import React from 'react'
import {
  AppState,
  StyleSheet,
  Platform,
  KeyboardAvoidingView,
  View
} from 'react-native';
import {
    useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { Audio, InterruptionModeAndroid, InterruptionModeIOS } from 'expo-av';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StatusBar } from 'expo-status-bar';
import { GestureHandlerRootView, GestureType } from "react-native-gesture-handler";

// Internal imports

import VerticalDivider from './components/VerticalDivider';
import ChatInput from './components/ChatInput';
import ChatMessages from './components/ChatMessages';
import ChatHeader from './components/ChatHeader';
import ChatMessageSuggestions from './components/ChatMessageSuggestions';
import WebsocketManager from './network/websocket/websocketManager';
import ReadableError from './util/ReadableError';
import * as WhisperAPI from './network/api/whisper';
import * as UUID from './util/UUID';
import * as Toast from './util/Toast';
import * as Analytics from './util/Analytics'
import * as AppSettings from './util/AppSettings'
import { ChatData, ChatDataStatus, ChatDataType } from './types/ChatData';
import { Voice } from 'expo-speech';
import { getVoices, TTSHandler } from './util/TTSHandler';
import Menu from './components/Menu';
import WebSafeEdgePanGesture from './components/WebSafeEdgePanGesture';
import WebBackground from './components/WebBackground';
import * as MessageFactory from './util/MessageFactory'
import { PremiumTTSHandler } from './util/PremiumTTSHandler';
import { PremiumWebTTSHandler } from './util/PremiumWebTTSHandler';

const STORAGE_KEY_CHAT_HISTORY = '@storage_Chat_History_V2';
const STORAGE_KEY_AUDIO_PERMISSIONS = '@storage_Audio_Permissions';
const STORAGE_KEY_SELECTED_VOICE = '@storage_Selected_Voice';
const STORAGE_KEY_TTS_ON = '@storage_TTS_On';
const STORAGE_KEY_VOICE_VOLUME_SETTING = '@storage_Voice_Volume_Setting';
const STORAGE_KEY_VOICE_RATE_SETTING = '@storage_Voice_Rate_Setting';
const STORAGE_KEY_VOICE_PITCH_SETTING = '@storage_Voice_Pitch_Setting';

const USE_PREMIUM_TTS = false

const HISTORY_LIMIT = 50

function Home(): JSX.Element {
    const ttsButtonGestureHandlerRef: React.MutableRefObject<GestureType | undefined> = useRef(undefined)

    const [loading, setLoading] = useState(true)
    const [submittingText, setSubmittingText] = useState(false)
    const [submittingVoice, setSubmittingVoice] = useState(false)
    const [clearingHistory, setClearingHistory] = useState(false)
    const [chatData, setChatData] = useState<ChatData[]>([])
    const [recording, setRecording] = useState<Audio.Recording | undefined>()
    const [recordingStartedAt, setRecordingStartedAt] = useState<Date | undefined>()
    const [recordingInitializing, setRecordingInitializing] = useState(false)
    const [shouldStopRecording, setShouldStopRecording] = useState(false)
    const [shouldSyncData, setShouldSyncData] = useState(false)
    const [whisperAbortController, setWhisperAbortController] = useState<AbortController | undefined>()
    const [lastMessageSentAt, setLastMessageSentAt] = useState<Date | undefined>()

    const ttsHandler = useRef<PremiumTTSHandler | PremiumWebTTSHandler | TTSHandler | undefined>()

    // Web Specific audio playback refs
    const audioElementRef = useRef(null);
    const audioContextRef = useRef(Platform.OS === 'web' && new (window.AudioContext)());   

    const [ttsOn, setTTSOn] = useState(false)
    const [ttsInProgress, setTTSInProgress] = useState(false)

    const [availableVoices, setAvailableVoices] = useState<Voice[]>([])
    const [selectedVoice, setSelectedVoice] = useState<Voice | undefined>()
    const [pitch, setPitch] = useState(1)
    const [volume, setVolume] = useState(1)
    const [rate, setRate] = useState(1)

    const [menuOpen, setMenuOpen] = useState(false)
    
    const insets = useSafeAreaInsets();

    function makeNextChatDataItem(type: ChatDataType, value?: string) {
        const uuid = UUID.getRandomUUID()

        setChatData(chatData => chatData.concat({ 
            type, 
            value, 
            status: ChatDataStatus.ACTIVE, 
            uuid, 
            createdAt: new Date() 
        }).slice(-HISTORY_LIMIT))

        return uuid
    }

    function updateChatDataItem(uuid: string, value?: string, status?: ChatDataStatus) {
        setChatData(chatData => {
            const index = chatData.findIndex(o => o.uuid === uuid)
            const chatDataItem = chatData[index]
            const newChatDataItem = { ...chatDataItem }
            if (value !== undefined) newChatDataItem.value = value
            if (status === ChatDataStatus.FAILED) newChatDataItem.value = newChatDataItem.value?.trim()
            if (status !== undefined) newChatDataItem.status = status
            let _chatData = [...chatData]
            _chatData[index] = newChatDataItem
            return _chatData
        })
    }

    function removeChatDataItems(uuids: string[]) {
        setChatData(chatData => {
            let _chatData = [...chatData]
            for (let uuid of uuids) {
                const index = _chatData.findIndex(o => o.uuid === uuid)
                if (index > -1) _chatData.splice(index,1)
            }
            return _chatData
        })
    }

    async function getCompletion(prompt: string, promptUUID: string, completionUUID: string) {
        //const uri = "wss://text-machina-api.ngrok.app"
        const uri = 'wss://text-machina-api.herokuapp.com'

        const conversation = MessageFactory.makeAPIMessages(chatData)

        let ws = await WebsocketManager.createSocket(promptUUID, uri);
        let reject: (reason?: any) => void
        let error: Error | WebSocketErrorEvent

        let headers = {
            'x-device-id': await UUID.getDeviceUUID(),
            'application-type': Platform.OS
        }

        function onSocketError(error: Error | Event) {
            error = error
            Toast.showError('Something went wrong! Try again!')
            WebsocketManager.destroy(promptUUID);
            reject(error)
        }

        const promise = new Promise<string>((resolve, _reject) => {
            reject = _reject
            let completion: string = ''

            ws.onopen = () => {
                ws.send(JSON.stringify({ prompt, conversation, uuid: promptUUID, headers }))
            }

            ws.onmessage = e => {
                const { text, uuid, error } = JSON.parse(e.data);
                if (error) return onSocketError(error)
                if (promptUUID !== uuid) return;
                completion += text;
                ttsHandler.current?.bufferText(text)
                updateChatDataItem(completionUUID, completion)
            };

            ws.onerror = onSocketError;

            ws.onclose = e => {
                ttsHandler.current?.endBuffer()
                if (e.code !== 1000 && e.code !== 1001) return onSocketError(error)
                if (error) return
                WebsocketManager.destroy(promptUUID);
                resolve(completion.trim())
            };
        })

        return promise
    }

    async function requestAudioPermissions(initialization: boolean) {
        try {
            if (initialization === true) {
                const attempted = await AsyncStorage.getItem(STORAGE_KEY_AUDIO_PERMISSIONS)
                if (attempted === 'true') return
                await AsyncStorage.setItem(STORAGE_KEY_AUDIO_PERMISSIONS, 'true')
            }

            const result = await Audio.requestPermissionsAsync();
            if (!result.granted)
                Toast.showError('Microphone permission denied! \nTap here to enable.', () => {
                    AppSettings.openAppSettings()
            })
        } catch(err) {
            console.error('requestAudioPermissions:', err)
        }
    }

    const enableSound = async () => {
        const soundObject = new Audio.Sound()
        if (Platform.OS === "ios") {
          await Audio.setAudioModeAsync({
            playsInSilentModeIOS: true,
            interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
            interruptionModeIOS: InterruptionModeIOS.MixWithOthers
          });
          await soundObject.loadAsync(require("./assets/audio/empty.mp3"));
          await soundObject.playAsync();
        }
      };

    useEffect(() => {
        requestAudioPermissions(true).catch((err) => console.error('requestAudioPermissions:', err))

        const subscription = AppState.addEventListener('change', nextAppState => {
            if (nextAppState === 'background' || nextAppState === 'inactive') {
                stopGenerating().catch(console.error)
            }
            if (nextAppState === 'active') enableSound().catch(console.error)
        })

        Promise.all([
            loadData(), 
            initializeVoice(false),
            enableSound()
        ]).catch(console.error).finally(() => setLoading(false))

        if (Platform.OS === 'web') {
            // Initialize analytics
            require('./util/WebAnalytics')
            Analytics.logEvent(Analytics.AnalyticsEvent.WEB_MAIN_SCREEN_VIEW)
        }

        return () => {
            subscription.remove()
        }
    }, [])

    async function loadData() {
        try {
            const jsonValue = await AsyncStorage.getItem(STORAGE_KEY_CHAT_HISTORY)
            if (jsonValue === null) return;
            setChatData(JSON.parse(jsonValue))
        } catch (err) {
            console.error('loadData:', err)
        }
    }

    function getDefaultVoice(voices: Voice[]) {
        let filtered = voices.filter(o => o.language === 'en-US')
        if (!filtered || filtered.length === 0) return voices[0]
        switch (Platform.OS) {
            case 'web':
                return filtered.find(o => o.name.toLowerCase().includes('samantha')) || filtered[0]
            case 'ios':
                return filtered.find(o => o.name.toLowerCase().includes('samantha')) || filtered[0]
            case 'android':
                return filtered.find(o => o.name.toLowerCase().includes('en-us-language')) || filtered[0]
            default: return voices[0]
        }
    }

    async function initializeVoice(override: boolean = false) {
        let storageVoiceID: string | undefined; 
        
        try {
            const storageVoice = await AsyncStorage.getItem(STORAGE_KEY_SELECTED_VOICE)
            if (storageVoice !== null) storageVoiceID = storageVoice
        } catch {}

        try {
            const voices = await getVoices()
            setAvailableVoices(voices)
            if (voices.length === 0) return
            const storageSelectedVoice = voices.find(o => o.identifier === storageVoiceID)
            setSelectedVoice(storageSelectedVoice ? storageSelectedVoice : getDefaultVoice(voices))
            const ttsON = await AsyncStorage.getItem(STORAGE_KEY_TTS_ON)

            const loadedVolume = await AsyncStorage.getItem(STORAGE_KEY_VOICE_VOLUME_SETTING)
            const loadedPitch = await AsyncStorage.getItem(STORAGE_KEY_VOICE_PITCH_SETTING)
            const loadedRate = await AsyncStorage.getItem(STORAGE_KEY_VOICE_RATE_SETTING)

            if (loadedVolume) setVolume(parseFloat(loadedVolume))
            if (loadedPitch) setPitch(parseFloat(loadedPitch))
            if (loadedRate) setRate(parseFloat(loadedRate))

            if ((ttsON === 'true' || override === true) && voices.length > 0) {
                ttsHandler.current?.destroy()
                let handler = USE_PREMIUM_TTS ? 
                    (Platform.OS === 'web' ? 
                        new PremiumWebTTSHandler(audioElementRef, audioContextRef) :
                        new PremiumTTSHandler()
                    ) :
                    new TTSHandler(
                        storageSelectedVoice ? storageSelectedVoice.identifier : voices[0].identifier,
                        loadedVolume ? parseFloat(loadedVolume) : volume,
                        loadedPitch ? parseFloat(loadedPitch) : pitch,
                        loadedRate ? parseFloat(loadedRate) : rate
                    )
                handler.setListener((value) => setTTSInProgress(value))
                ttsHandler.current = handler
                setTTSOn(true)
                await AsyncStorage.setItem(STORAGE_KEY_TTS_ON, 'true')
            }
        } catch { }
    }

    useEffect(() => {
        if (shouldSyncData) syncData().catch((err) => console.error('syncData:', err))
    }, [chatData, shouldSyncData])

    async function syncData() {
        if (chatData.length === 0) return
        setShouldSyncData(false)
        try {
            const jsonValue = JSON.stringify(chatData.filter(o => o.status === ChatDataStatus.COMPLETE))
            await AsyncStorage.setItem(STORAGE_KEY_CHAT_HISTORY, jsonValue)
        } catch (err) {
            console.error('syncData:', err)
        }
    }

    async function clearHistory() {
        if (submittingText || submittingVoice) return;

        await ttsHandler.current?.stopAndClear()

        setClearingHistory(true)
        try {
            await AsyncStorage.removeItem(STORAGE_KEY_CHAT_HISTORY)
            setChatData([])
            Analytics.logEvent(Analytics.AnalyticsEvent.CLEAR_HISTORY)
        } catch (err) {
            console.error('clearHistory:', err)
        } finally {
            setClearingHistory(false)
        }
    }

    async function toggleTTS() {
        if (ttsHandler.current || ttsOn) {
            Analytics.logEvent(Analytics.AnalyticsEvent.TTS_TOGGLED_OFF)
            ttsHandler.current?.destroy()
            ttsHandler.current = undefined
            setTTSOn(false)
            await AsyncStorage.setItem(STORAGE_KEY_TTS_ON, 'false')
        } else {
            Analytics.logEvent(Analytics.AnalyticsEvent.TTS_TOGGLED_ON)
            if (availableVoices.length > 0 && selectedVoice) {
                let handler = USE_PREMIUM_TTS ? 
                    (Platform.OS === 'web' ? 
                        new PremiumWebTTSHandler(audioElementRef, audioContextRef) :
                        new PremiumTTSHandler()
                    ) :
                    new TTSHandler(selectedVoice.identifier, volume, pitch, rate)
                handler.setListener((value) => setTTSInProgress(value))
                ttsHandler.current = handler
                setTTSOn(true)
                await AsyncStorage.setItem(STORAGE_KEY_TTS_ON, 'true')
            } else {
                await initializeVoice(true)
            }
        }
    }

    async function stopGenerating() {
        await ttsHandler.current?.stopAndClear()
        if (chatData.length > 1 && chatData[chatData.length - 2].status === ChatDataStatus.ACTIVE) 
            WebsocketManager.destroy(chatData[chatData.length - 2].uuid)
        if (whisperAbortController) whisperAbortController.abort()
    }

    async function sendPrompt(prompt: string) {
        Analytics.logEvent(Analytics.AnalyticsEvent.TEXT_MESSAGE_SENT)
        setLastMessageSentAt(new Date())
        setSubmittingText(true)

        const promptUUID = makeNextChatDataItem(ChatDataType.PROMPT, prompt)
        const completionUUID = makeNextChatDataItem(ChatDataType.COMPLETION)

        try {
            const completion = await getCompletion(prompt, promptUUID, completionUUID)

            if (completion.trim() === '') throw new Error('Empty completion')

            updateChatDataItem(promptUUID, prompt, ChatDataStatus.COMPLETE)
            updateChatDataItem(completionUUID, completion, ChatDataStatus.COMPLETE)
            setShouldSyncData(true)
        } catch (error) {
            console.error('sendPrompt:', error)
            updateChatDataItem(promptUUID, undefined, ChatDataStatus.FAILED)
            updateChatDataItem(completionUUID, undefined, ChatDataStatus.FAILED)
        } finally {
            setSubmittingText(false)
        }
    }

    async function submitRecording(uri: string) {
        Analytics.logEvent(Analytics.AnalyticsEvent.VOICE_MESSAGE_SENT)

        setLastMessageSentAt(new Date())

        let promptUUID, completionUUID;

        try {
            const abortController = new AbortController()
            setWhisperAbortController(abortController)

            const prompt = await WhisperAPI.post(uri, abortController)

            promptUUID = makeNextChatDataItem(ChatDataType.PROMPT, prompt)
            completionUUID = makeNextChatDataItem(ChatDataType.COMPLETION)

            const completion = await getCompletion(prompt, promptUUID, completionUUID)

            if (completion.trim() === '') throw new Error('Empty completion')

            updateChatDataItem(promptUUID, prompt, ChatDataStatus.COMPLETE)
            updateChatDataItem(completionUUID, completion, ChatDataStatus.COMPLETE)
            setShouldSyncData(true)
        } catch (error) {
            if (promptUUID && completionUUID) {
                updateChatDataItem(promptUUID, undefined, ChatDataStatus.FAILED)
                updateChatDataItem(completionUUID, undefined, ChatDataStatus.FAILED)
            }
            if (error instanceof ReadableError)
            Toast.showError(error.message)
            console.error('submitRecording:', error)
        } finally {
            setWhisperAbortController(undefined)
            setSubmittingVoice(false)
        }
    }
  
    async function startRecording(){
        try {
            await ttsHandler.current?.stopAndClear()
        } catch {

        }
        setRecordingInitializing(true)
        try {
            await requestAudioPermissions(false);
            await Audio.setAudioModeAsync({
                allowsRecordingIOS: true,
            });
            const { recording } = await Audio.Recording.createAsync( Audio.RecordingOptionsPresets.HIGH_QUALITY);
            setRecording(recording);
            setRecordingStartedAt(new Date())
        } catch (error) {
            setRecording(undefined)
            setRecordingStartedAt(undefined)
        } finally {
            setRecordingInitializing(false)
        }
    };
  
    function stopRecording() {
        setShouldStopRecording(true)
    };

    async function _stopRecording() {
        setSubmittingVoice(true)
        try {
            if (!recording || !recordingStartedAt) throw new Error('Not recording');
            if ((new Date().getTime() - recordingStartedAt.getTime()) < 250) {
                await recording.stopAndUnloadAsync();
                throw new Error('Too short')
            }
            await Audio.setAudioModeAsync({
                allowsRecordingIOS: false,
                playsInSilentModeIOS: true
            });
            await recording.stopAndUnloadAsync();
            const uri = recording.getURI()
            setRecording(undefined);
            setRecordingStartedAt(undefined)
            if (!uri) throw new Error('No recording uri found') 
            await submitRecording(uri)
        } catch (error) {
            setSubmittingVoice(false)
            setRecording(undefined)
            setRecordingStartedAt(undefined)
        }
    };

    async function tapToRetry(uuid: string) {
        if (submittingText || submittingVoice) return
        let promptItem, completionItem;
        const index = chatData.findIndex(o => o.uuid === uuid)
        if (index < 0) return
        const item = chatData[index]
        if (item.type === ChatDataType.COMPLETION) {
            completionItem = item
            promptItem = chatData[index - 1]
        } else {
            promptItem = item
            completionItem = chatData[index + 1]
        }
        if (!completionItem || !promptItem) return
        const prompt = promptItem.value
        if (!prompt) return
        removeChatDataItems([promptItem.uuid, completionItem.uuid])
        sendPrompt(prompt)
    }

    useEffect(() => {
        if (shouldStopRecording && !recordingInitializing) {
            setShouldStopRecording(false)
            _stopRecording().catch((err) => console.error('_stopRecording', err))
        }
    }, [shouldStopRecording, recordingInitializing])

    useEffect(() => {
        if (!selectedVoice) return
        if (ttsHandler.current instanceof TTSHandler) ttsHandler.current?.changeVoice(selectedVoice.identifier)
        AsyncStorage.setItem(STORAGE_KEY_SELECTED_VOICE, selectedVoice.identifier).catch(console.error)
    }, [selectedVoice])

    useEffect(() => {
        if (ttsHandler.current instanceof TTSHandler) ttsHandler.current?.changeVolume(volume)
    }, [volume])

    useEffect(() => {
        if (ttsHandler.current instanceof TTSHandler) ttsHandler.current?.changeRate(rate)
    }, [rate])

    useEffect(() => {
        if (ttsHandler.current instanceof TTSHandler) ttsHandler.current?.changePitch(pitch)
    }, [pitch])

    return (
        <GestureHandlerRootView 
            style={[
                styles.main, 
                Platform.OS === 'web' && styles.webMain,
                {
                    paddingTop: insets.top,
                    paddingBottom: insets.bottom,
                    paddingRight: insets.right,
                    paddingLeft: insets.left,
                }
            ]}
        >
            <StatusBar
                style={'dark'}
            />
            {Platform.OS === 'web' && (
                <audio ref={audioElementRef} style={{ display: 'none' }} />
            )}
            {Platform.OS === 'web' && <WebBackground/>}
            <WebSafeEdgePanGesture
                canPanFromLeftEdge={!menuOpen}
                canPanFromRightEdge={menuOpen}
                ttsButtonGestureHandler={undefined}
                didPanFromLeftEdge={() => {
                    if (availableVoices.length > 0) return setMenuOpen(true)
                    initializeVoice().finally(() => setMenuOpen(true))
                }}
                didPanFromRightEdge={() => setMenuOpen(false)}
            >
                <KeyboardAvoidingView 
                    behavior={(Platform.OS === 'ios')? "padding" : undefined}
                    keyboardVerticalOffset={Platform.select({ios: 0, android: 500})}
                    style={[styles.container, Platform.OS === 'web' && styles.webContainer]}
                    >
                    <ChatHeader 
                        toggleMenu={() => {
                            if (availableVoices.length > 0) return setMenuOpen(menuOpen => !menuOpen)
                            initializeVoice().finally(() => setMenuOpen(menuOpen => !menuOpen))
                        }}
                        toggleTTS={toggleTTS}
                        ttsOn={ttsOn}
                        menuOpen={menuOpen}
                        clearHistory={clearHistory}
                    />
                    <VerticalDivider light={false}/>
                    { 
                        menuOpen ? 
                        <Menu
                            key={'menu'}
                            availableVoices={availableVoices}
                            selectedVoice={selectedVoice}
                            clearHistory={() => {
                                setMenuOpen(false)
                                clearHistory()
                            }}
                            selectVoice={((voice) => {
                                Toast.showSuccess('Set voice to - ' + voice.name)
                                setSelectedVoice(voice)
                            })}
                            setVolume={async (value: number) => {
                                setVolume(value)
                                AsyncStorage.setItem(STORAGE_KEY_VOICE_VOLUME_SETTING, value.toString()).catch(console.error)
                            }}
                            setPitch={async (value: number) => {
                                setPitch(value)
                                AsyncStorage.setItem(STORAGE_KEY_VOICE_PITCH_SETTING, value.toString()).catch(console.error)
                            }}
                            setRate={async (value: number) => {
                                setRate(value)
                                AsyncStorage.setItem(STORAGE_KEY_VOICE_RATE_SETTING, value.toString()).catch(console.error)
                            }}
                            volume={volume}
                            pitch={pitch}
                            rate={rate}
                        />
                        :
                        [
                            loading ? <View key={'loading-view'} style={{ flexGrow: 1 }}/> :
                            chatData.length > 0  ? 
                            <ChatMessages 
                                key={'chat-messages'}
                                lastMessageSentAt={lastMessageSentAt}
                                chatData={chatData}
                                submittingText={submittingText}
                                submittingVoice={submittingVoice}
                                didCopyMessage={() => {
                                    Toast.showSuccess('Message copied to clipboard! ✅')
                                }}
                                tapToRetry={tapToRetry}
                            />
                            :
                            <ChatMessageSuggestions
                                key={'chat-suggestions'}
                                sendPrompt={sendPrompt}
                            />,
                            <VerticalDivider key={'chat-main-divider'} light={false}/>,
                            <ChatInput 
                                key={'chat-input'}
                                recording={recording !== undefined || recordingInitializing === true} 
                                sendPrompt={sendPrompt} 
                                startRecording={startRecording} 
                                stopRecording={stopRecording}
                                stopGenerating={stopGenerating}
                                ttsButtonGestureHandlerRef={undefined}
                                submitting={submittingText === true || submittingVoice === true || loading === true || ttsInProgress === true}
                                wiping={clearingHistory}
                            />
                        ]
                    }
                </KeyboardAvoidingView>
            </WebSafeEdgePanGesture>
        </GestureHandlerRootView>
    );
}

const styles = StyleSheet.create({
  main: {
    flex: 1
  },
  webMain: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  webContainer: {
    width: '100%',
    maxWidth: 768,
    borderRightColor: 'rgba(0, 0, 0, .2)',
    borderRightWidth: 1,
    borderLeftColor: 'rgba(0, 0, 0, .2)',
    borderLeftWidth: 1,
    backgroundColor: 'white'
  }
});

export default Home;
