import { StyleSheet, View } from "react-native";

function WebBackground() {
    return <View style={styles.backgroundView}/>
}

const styles = StyleSheet.create({
    backgroundView: {
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'row',
        backgroundColor: 'black',
        opacity: 0.02
    },
});

export default WebBackground