import { Image, ImageStyle, ImageURISource, StyleProp } from "react-native"
import { Image as ExpoImage, ImageStyle as ExpoImageStyle, ImageContentFit } from 'expo-image'
import { PropsWithChildren } from "react"

export function BetterImage(props: { source: ImageURISource, style: StyleProp<ImageStyle> }) {
    return <Image source={props.source} defaultSource={props.source} style={props.style}/>
}

export function BetterExpoImage(props: PropsWithChildren<{ 
    source: ImageURISource,
    style: ExpoImageStyle | ExpoImageStyle[] | undefined,
    contentFit: ImageContentFit | undefined
}>) {
    return <ExpoImage 
        style={props.style}
        source={props.source} 
        contentFit={props.contentFit}
        placeholder={props.source} 
    >{props.children}</ExpoImage>
}