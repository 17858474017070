import {useEffect, useState, useRef, PropsWithChildren} from 'react'
import React from 'react'
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Pressable,
  Platform,
  LayoutChangeEvent,
  LayoutRectangle
} from 'react-native';
import { LinearGradient, LinearGradientPoint } from 'expo-linear-gradient';
import Svg, { Rect } from 'react-native-svg';
import LottieView from 'lottie-react-native';
import { Gesture, GestureDetector, GestureType, LongPressGestureHandler, ManualGesture, State, TapGestureHandler } from "react-native-gesture-handler";

// Internal Imports

import * as HapticFeedback from '../util/HapticFeedback'
import { BetterExpoImage } from './BetterImage';
import Color from '../util/Color'

interface IncompleteGradientProps {
    start: LinearGradientPoint,
    end: LinearGradientPoint,
    locations: number[],
    offset: number
}

interface GradientProps {
    start: LinearGradientPoint,
    end: LinearGradientPoint,
    locations: number[],
    offset: number,
    colors: string[]
}

type ChatInputProps = {
    recording: boolean
    sendPrompt: (prompt: string) => void
    startRecording: () => void
    stopRecording: () => void
    stopGenerating: () => void
    ttsButtonGestureHandlerRef: React.MutableRefObject<GestureType | undefined> | undefined
    wiping: boolean
    submitting: boolean
}

const vertGradientProps: IncompleteGradientProps = {
    start: [0,0],
    end: [0,1],
    locations: [0,1],
    offset: 0
}

const diagGradientProps: IncompleteGradientProps = {
    start: [0,0],
    end: [1,1],
    locations: [0,1],
    offset: 0
}

const blackGradientColors = ['#000000CC', '#000000']
const generatingGradientColors = ['#666666CC', '#666666']

function makeVoiceInactiveGradientProps(offset: number | undefined) {
    if (offset === undefined) return { ...vertGradientProps, colors: blackGradientColors }

    const subOffset = offset % 2

    return {
        start: diagGradientProps.start,
        end: diagGradientProps.end,
        locations: subOffset < 1 ? [0, subOffset] : [subOffset - 1, 1],
        offset,
        colors: offset > 2 ?  generatingGradientColors.slice().reverse() : generatingGradientColors
    }
}

const defaultVoiceInctiveGradientProps = makeVoiceInactiveGradientProps(0)

function TTSButtonGestureHandler(props: PropsWithChildren<{chatInputButtonLayout: LayoutRectangle} & ChatInputProps>) {
    let gesture;

    const onPressedIn = () => {
        if (props.wiping) return

        HapticFeedback.lightFeedback()

        if (props.submitting)
            props.stopGenerating()
        else
            props.startRecording();
    }

    const onPressedOut = () => {
        HapticFeedback.lightFeedback()
        props.stopRecording();
    }

    if (Platform.OS !== 'web') 
        gesture = Gesture.Manual()
            .withRef(props.ttsButtonGestureHandlerRef)
            .onTouchesDown(onPressedIn)
            .onTouchesUp(onPressedOut)

    return Platform.OS === 'web' ? 
        (<LongPressGestureHandler 
            onHandlerStateChange={({nativeEvent}) => {
                console.log(nativeEvent.state)
                switch (nativeEvent.state) {
                    case (State.ACTIVE): return onPressedIn();
                    case (State.END): return onPressedOut();
                    default: return
                }
            }}
            shouldCancelWhenOutside={false}
        >
            {props.children}
        </LongPressGestureHandler>) :
        <GestureDetector 
            gesture={gesture as ManualGesture}
        >
            {props.children}
        </GestureDetector>
}

function ChatInputButton(props: ChatInputProps & { voiceInactiveGradientProps: GradientProps }) {
    const [chatInputButtonLayout, setChatInputButtonLayout] = useState<LayoutRectangle>({height: 0, width: 0, x: 0, y: 0})

    return <TTSButtonGestureHandler chatInputButtonLayout={chatInputButtonLayout} {...props}>
        <Pressable
            nativeID='chat-input-pressable'
            style={[
                styles.voiceButtonPressable
            ]}
            onPress={() => {
                if (!props.submitting || props.wiping) return
                HapticFeedback.lightFeedback()
                props.stopGenerating()
            }}
            onLayout={({nativeEvent}) => setChatInputButtonLayout(nativeEvent.layout)}
        >
            <LinearGradient 
                start={props.voiceInactiveGradientProps.start}
                end={props.voiceInactiveGradientProps.end}
                locations={[props.voiceInactiveGradientProps.locations[0], props.voiceInactiveGradientProps.locations[1]]}
                colors={props.voiceInactiveGradientProps.colors} 
                style={styles.voiceButtonLinearGradient}
            >
                <View style={[styles.buttonContentContainer, {justifyContent: 'flex-start'}]}>
                    { props.wiping ? <></> :
                        props.submitting ?
                        <Svg
                            width="30"
                            height="18"
                            viewBox="0 0 100 100"
                        >
                            <Rect
                                x="0"
                                y="0"
                                width="100"
                                height="100"
                                fill="white"
                            />
                        </Svg> :
                        <BetterExpoImage
                            style={{height: 22, width: 30}}
                            contentFit='contain'
                            source={require('../assets/img/icn_jackchat_mic.svg')}
                        />
                    }
                </View>
                <Text 
                    style={styles.voiceButtonText}
                >
                    { props.wiping ? 'Please Wait' : props.submitting ? "Stop Answering" : props.recording ? 'Listening' : 'Push to Talk'}
                </Text>
                <View style={[styles.buttonContentContainer, {justifyContent: 'flex-start'}]}/>
            </LinearGradient>
        </Pressable>
    </TTSButtonGestureHandler>
}

const DEFAULT_TEXT_INPUT_HEIGHT = 20

function ChatInput(props: ChatInputProps): JSX.Element {
    const textInputRef = useRef<TextInput>(null)

    const [prompt, setPrompt] = useState('')
    const [textInputHeight, setTextInputHeight] = useState(DEFAULT_TEXT_INPUT_HEIGHT)
    const [voiceInactiveGradientProps, setVoiceInactiveGradientProps] = useState<GradientProps>(defaultVoiceInctiveGradientProps)

    function getGeneratingGradientAnimationInterval() {
        return setInterval(() => {
            setVoiceInactiveGradientProps(voiceInactiveGradientProps => { 
                const offset = (((voiceInactiveGradientProps.offset * 100) + 5) % 400) / 100
                return makeVoiceInactiveGradientProps(offset)
            })
        }, 25)
    }

    useEffect(() => {
        let interval: NodeJS.Timer | undefined

        if (props.submitting || props.wiping || props.recording)
            interval = getGeneratingGradientAnimationInterval()
        else
            setVoiceInactiveGradientProps(makeVoiceInactiveGradientProps(undefined))

        return () => {
            if (interval) clearInterval(interval)
        }
    }, [props.submitting, props.wiping, props.recording])

    function submit() {
        if (props.submitting || props.wiping || prompt.trim().length === 0) return
        props.sendPrompt(prompt)
        HapticFeedback.lightFeedback()
        setPrompt('')
        setTextInputHeight(DEFAULT_TEXT_INPUT_HEIGHT)
    }

    return (
        <View
            style={[styles.chatInputContainer]}
        >
            {
                props.recording &&
                <View style={{ position: 'absolute', top: 0, left: -150, width: '300%', height: '200%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}>
                    <LottieView
                        autoPlay
                        loop
                        style={{width: '100%'}} 
                        resizeMode='contain' 
                        autoSize={false} 
                        source={require('../assets/lottie/bubbles.json')} 
                    />
                </View>
            }
            <View
                style={[styles.textInputContainer]}
            >
                <Pressable style={styles.textInputWrapper} onPress={() => textInputRef.current?.focus()}>
                    <TextInput
                        ref={textInputRef}
                        style={[
                            styles.textInput, 
                            { color: 'black', height: Math.min(125, Math.max(DEFAULT_TEXT_INPUT_HEIGHT, textInputHeight))
                        }]}
                        onChangeText={(textChange) => {
                            if (textChange.slice(-1) === '\n' && (prompt.trim() === '' || props.submitting)) return setTextInputHeight(0)
                            setPrompt(textChange)
                        }}
                        placeholder={props.recording ? '' : ' Chat with Jack...'}
                        selectionColor={props.recording ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,.3)'}
                        value={prompt}
                        placeholderTextColor={'rgba(0,0,0,0.5)'}
                        multiline={true}
                        onKeyPress={(value: any) => { 
                            if (value.nativeEvent.key === 'Enter' && !value.shiftKey) submit()
                            if (value.nativeEvent.key === 'Enter' && value.shiftKey && props.submitting) setPrompt(prompt => prompt + '\n')
                        }}
                        onContentSizeChange={({nativeEvent}) => { setTextInputHeight(prompt.trim() === '' ? 0 : nativeEvent.contentSize.height)}}
                    />
                </Pressable>
                <View
                    style={{position: 'absolute', top: 0, right: 0, height: '100%', justifyContent: 'center', alignItems: 'center'}}
                >
                    <Pressable
                        style={{
                            height: 50, 
                            width: 50, 
                            paddingTop: 5, 
                            justifyContent: 'center', 
                            alignItems: 'center'
                        }}
                        onPress={() => {
                            if (props.submitting || props.wiping || prompt.trim().length === 0) return
                            submit()
                        }}
                    >
                        <View
                            style={{
                                height: 40, 
                                width: 40, 
                                paddingTop: 2,
                                paddingRight: 2,
                                justifyContent: 'center', 
                                alignItems: 'center',
                                opacity: (props.submitting || props.wiping || prompt.trim().length === 0) ? 0.5 : 1,
                                borderRadius: 20,
                                backgroundColor: (props.submitting || props.wiping || prompt.trim().length === 0) ? undefined : Color.blue
                            }}
                        >
                            <BetterExpoImage
                                style={{height: 20, width: 20, tintColor: 'white'}}
                                contentFit='contain'
                                source={(props.submitting || props.wiping || prompt.trim().length === 0) ? require('../assets/img/icn_jackchat_send.svg') : require('../assets/img/icn_jackchat_send_white.svg')}
                            />
                        </View>
                    </Pressable>
                </View>
            </View>
            <View
                style={[styles.buttonContainer]}
            >
                <ChatInputButton
                    {...props}
                    voiceInactiveGradientProps={voiceInactiveGradientProps}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
  chatInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: 24,
    paddingLeft: 24,
    overflow: Platform.OS === 'ios' ? 'visible' : 'hidden',
    paddingTop: 0,
    paddingBottom: Platform.OS === 'ios' ? 16 : 24
  },
  textInputContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  textInputWrapper: {
    flex: 1,
    minHeight: 50,
    flexWrap: 'wrap',
    marginLeft: 4,
    marginRight: 54,
    justifyContent: 'center'
  },
  textInput: {
    width: '100%',
    fontSize: 16,
    flexWrap: 'wrap',
    marginLeft: 4,
    marginRight: 4,
    marginTop: 24,
    marginBottom: 24,
    ...(Platform.OS === 'web' ? {outlineStyle: 'none'} : {})
  },
  textSendPressable: {
    width: 50,
    height: 50,
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
    alignItems: 'center',
    borderRadius: 10,
    overflow:'hidden',
    justifyContent: 'center',
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: {width: 4, height: 12}
  },
  textSendLinearGradient: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    overflow:'hidden',
    justifyContent: 'center'
  },
  voiceButtonPressable: {
    flexGrow: 1,
    width: 'auto',
    height: 50,
    borderRadius: 10,
    overflow:'hidden',
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
    backgroundColor: 'black',
    justifyContent: 'center'
  },
  voiceButtonLinearGradient: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: Platform.OS === 'web' ? 16 :16,
    paddingRight: Platform.OS === 'web' ? 16 :16
  },
  voiceButtonText: {
    flexGrow: 1,
    color: 'white',
    fontSize: 16,
    fontWeight: "700",
    textAlign: 'center'
  },
  voiceButtonEndText: {
    color: 'white',
    fontSize: 12,
    fontWeight: "700",
    textAlign: 'right',
    width: 33,
  },
  voiceButtonPaddingActive: {
    width: 36,
    height: 22,
  },
  voiceButtonPadding: {
    width: 18,
    height: 22
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContentContainer: {
        flexGrow: 1, 
        flexShrink: 0,
        flex: 1,
        display: 'flex', 
        height: '100%',
        flexDirection: 'row', 
        alignItems: 'center',
        marginRight: 8
    },
});

export default ChatInput;
