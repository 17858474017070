import * as Haptics from 'expo-haptics';
import { Platform } from 'react-native';

export function lightFeedback() {
    if (['web', 'macos', 'windows'].includes(Platform.OS)) return
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
    //Haptics.selectionAsync()
    //Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success).catch(err => console.error('Haptics.notificationAsync', err))
}

export function minorFeedback() {
    if (['web', 'macos', 'windows'].includes(Platform.OS)) return
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
    //Haptics.selectionAsync()
    //Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success).catch(err => console.error('Haptics.notificationAsync', err))
}