import React, { memo, useState } from 'react'
import {
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  Platform,
  View
} from 'react-native';
import LottieView from 'lottie-react-native';
import * as Clipboard from 'expo-clipboard';

// Internal Imports

import { BetterExpoImage } from './BetterImage';
import * as HapticFeedback from '../util/HapticFeedback'
import { ChatData, ChatDataStatus, ChatDataType } from '../types/ChatData';
import VerticalDivider from './VerticalDivider';

export type ChatMessageProps = {
    chatData: ChatData
    shouldHaveBottomDivider: boolean
    didCopyMessage: () => void
    tapToRetry: (uuid: string) => void
}

const userAvatarSrc = require('../assets/img/icn_jackchat_user.png')
const botAvatarSrc = require('../assets/img/icn_jackchat_bot.png')

function ChatMessage(props: ChatMessageProps) {
    return (
        <View>
            <TouchableWithoutFeedback
                onLongPress={ () => {
                    if (!props.chatData.value) return;
                    Clipboard.setStringAsync(props.chatData.value).catch(err => console.error('Clipboard.setStringAsync:', err))
                    HapticFeedback.lightFeedback()
                    props.didCopyMessage();
                }}
                onPress={() => { 
                    if (props.chatData.status === ChatDataStatus.FAILED) {
                        props.tapToRetry(props.chatData.uuid) 
                        HapticFeedback.lightFeedback()
                    }
                }}
            >
                <View
                    style={[
                        props.chatData.type === ChatDataType.PROMPT ? styles.selfResponse : styles.botResponse
                    ]}
                >
                    <View 
                        style={[
                            styles.responseContainer 
                        ]}
                    >
                        <BetterExpoImage 
                            contentFit='cover'
                            style={[
                                props.chatData.type === ChatDataType.PROMPT ? styles.userImage : styles.botImage,
                                styles.avatarImage
                            ]} 
                            source={props.chatData.type === ChatDataType.PROMPT ? userAvatarSrc : botAvatarSrc}
                        />
                        <View 
                            style={[styles.responseTextContainer]}
                        >
                            {
                                props.chatData.value !== undefined ?
                                <Text 
                                    style={styles.responseText} 
                                >
                                    {props.chatData.value}
                                </Text> :
                                props.chatData.status !== ChatDataStatus.FAILED ? 
                                <LottieView
                                    autoPlay
                                    loop
                                    style={{width:30, height: 34}} 
                                    resizeMode='cover' 
                                    autoSize={true} 
                                    source={require('../assets/lottie/loader.json')} 
                                /> : <></>
                            }
                            { (props.chatData.status === ChatDataStatus.FAILED && props.chatData.type === ChatDataType.COMPLETION) && 
                                <Text style={styles.retryText}>tap to retry</Text> 
                            }
                        </View>
                    </View>
                </View>
            </TouchableWithoutFeedback>
            { props.shouldHaveBottomDivider && <VerticalDivider light={true} height={1}/> }
        </View>
    )
}

const styles = StyleSheet.create({
    lottie: {
      width: 100,
      height: 100
    },
    responseContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 16
    },
    avatarImage: {
      marginRight: 13,
      justifyContent: 'center',
      alignItems: 'center'
    },
    botImage: {
        overflow: 'hidden',
      height: 45,
      width: 45
    },
    userImage: {
        overflow: 'hidden',
        borderRadius: 40,
        height: 45,
        width: 45
    },
    responseTextContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginRight: 10
    },
    responseText: {
      fontSize: 17,
      lineHeight: 22,
      fontWeight: "400",
      color: '#262626'
    },
    selfResponse: {
      backgroundColor: 'rgba(0, 0, 0, 0.00)'
    },
    botResponse: {
      backgroundColor: 'rgba(0, 0, 0, 0.02)'
    },
    retryText: {
        color: 'red',
        fontSize: 10
    }
});

const areEqual=(prevProps: ChatMessageProps, nextProps: ChatMessageProps) => {
    return prevProps.chatData.value === nextProps.chatData.value && 
    prevProps.chatData.status === nextProps.chatData.status &&
    prevProps.shouldHaveBottomDivider === nextProps.shouldHaveBottomDivider
}

//export default memo(ChatMessage, areEqual);
export default ChatMessage;
