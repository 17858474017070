import { Platform } from 'react-native';
import Toast from 'react-native-root-toast';

export function showSuccess(message: string, onPress?: () => void) {
    showToast(message, false, onPress)
}

export function showError(message: string, onPress?: () => void) {
    showToast(message, true, onPress)
}

function showToast(message: string, error: boolean, onPress?: () => void) {
    if (Platform.OS === 'web') return
    Toast.show(message, {
        position: 120,
        backgroundColor: 'white',
        textColor: 'black',
        opacity: 1,
        containerStyle: {
            padding: 20,
            borderLeftWidth: 5,
            borderLeftColor: error ? 'red' : '#90EE90'
        },
        textStyle: {
            fontWeight: "500",
            fontSize: 15,
            lineHeight: 24,
            textAlign: 'left'
        },
        onPress
    })
}