import * as Crypto from 'expo-crypto'
import AsyncStorage from '@react-native-async-storage/async-storage'

const STORAGE_KEY_DEVICE_UUID = '@storage_Device_UUID';

export async function getDeviceUUID() {
    try {
        let uuid = await AsyncStorage.getItem(STORAGE_KEY_DEVICE_UUID)
        if (!uuid) {
            const newUUID = Crypto.randomUUID()
            uuid = newUUID
            await AsyncStorage.setItem(STORAGE_KEY_DEVICE_UUID, newUUID)
        }
        return uuid
    } catch (err) {
        throw new Error('Could not retrieve or generate device UUID')
    }
}

export function getRandomUUID(): string {
    return Crypto.randomUUID()
}