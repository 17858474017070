import * as Speech from 'expo-speech';
import { Platform } from 'react-native';

export const SUPPORTED_LOCALES: { [key: string]: string } = {
    'en-US': 'English',
    'de-DE': 'German',
    'es-MX': 'Spanish',
    'fr-FR': 'French',
    'it-IT': 'Italian',
    'jp-JP': 'Japanese',
    'ko-KR': 'Korean',
    'pt-BR': 'Portuguese',
    'ru-RU': 'Russian',
    'th-TH': 'Thai',
    'zh-CN': 'Chinese'
}

export async function getVoices(): Promise<Speech.Voice[]> {
    try {
        let voices = await Speech.getAvailableVoicesAsync()

        let permittedVoices: Speech.Voice[] = []

        for (let voice of voices) {
            if (Platform.OS === 'android' && (voice.name.includes('-local') || voice.name.includes('-network'))) continue

            if (SUPPORTED_LOCALES[voice.language]) permittedVoices.push(voice)
        }

        return permittedVoices
    } catch {
        return []
    }
}

export class TTSHandler {
    private bufferSize = 100
    private voice: string
    private destroyed = false
    private consumingChunks = false
    private volume: number
    private pitch: number
    private rate: number

    private listener: ((active: boolean) => void) | null = null

    private chunks: string[] = []
    private buffer: string = ''

    constructor(voice: string, volume: number, pitch: number, rate: number) {
        this.voice = voice
        this.volume = volume
        this.pitch = pitch
        this.rate = rate
    }

    setListener(listener: (active: boolean) => void) {
        this.listener = listener
    }

    bufferText(text: string) {
        if (this.destroyed) return
        this.buffer += ' ' + text;
        if (this.buffer.length >= this.bufferSize || /[.!?]/.test(text)) this.consumeBuffer()
    }

    endBuffer() {
        if (this.destroyed) return
        if (this.buffer.trim()) this.consumeBuffer()
    }

    consumeBuffer() {
        if (this.destroyed) return
        this.chunks.push(this.buffer)
        this.buffer = ''

        if (this.consumingChunks) return

        this.consumeChunks()
    }

    consumeChunks() {
        if (this.chunks.length === 0 || this.destroyed === true) {
            this.consumingChunks = false
            this.listener?.(false)
            return
        }

        this.listener?.(true)

        this.consumingChunks = true

        const chunk = this.chunks.shift() || ''

        Speech.speak(chunk, { 
            voice: this.voice, 
            pitch: this.pitch, 
            rate: this.rate, 
            volume: this.volume, 
            onDone: this.consumeChunks.bind(this)
        })
    }

    async stopAndClear() {
        this.chunks = []
        this.buffer = ''
        await Speech.stop()
        this.listener?.(false)
        this.listener = null
    }

    changeVoice(voice: string) {
        this.voice = voice
    }

    changeVolume(volume: number) {
        this.volume = volume
    }

    changePitch(pitch: number) {
        this.pitch = pitch
    }

    changeRate(rate: number) {
        this.rate = rate
    }

    async destroy() {
        this.destroyed = true
        this.buffer = ''
        Speech.stop().catch(console.error)
    }
}