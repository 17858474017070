import React, { useEffect, useState } from 'react'
import {
    Keyboard,
    Pressable,
    StyleSheet,
    Text,
    TouchableWithoutFeedback,
    View
} from 'react-native';

import * as HapticFeedback from '../util/HapticFeedback'

const CHAT_SUGGESTIONS = [
    'How do you make scrambled eggs fluffy?',
    'Tell me about Woodstock 99',
    'Do robots dream of electric sheep?',
    'What is the meaning of life?',
    'Why do pigs have curly tails?',
    'What is the history of AI?',
    'Write threes haikus about pizza',
    'Give me a fitness plan',
    'How does electrity work?',
    'Is there alien life?',
    'Can I learn to fly?',
    'Pretend to be Taylor Swift',
    'Give me budgeting advice',
    "How far is Mars?",
    "What are the best albums of all time?",
    "Teach me about taxes",
    "Tell me a story about New York City",
    "How were the pyramids built?",
    "What are some must-watch movies?",
    "How many inches in a foot?",
    "I need help choosing a college",
    "Explain dry cleaning to me",
    "Tell me about the history of punk rock",
    "Is capitalism fair?"
]

type ChatMessageSuggestionsProps = {
    sendPrompt: (prompt: string) => void
}

function ChatMessageSuggestions(props: ChatMessageSuggestionsProps): JSX.Element {
    const [suggestions, setSuggestions] = useState<string[]>([])

    useEffect(() => {
        const shuffled = CHAT_SUGGESTIONS.sort(() => 0.5 - Math.random());
        setSuggestions(shuffled.slice(0, 3));
    }, [])

    return (
        <TouchableWithoutFeedback
            onPress={Keyboard.dismiss}
            onLongPress={Keyboard.dismiss}
        >
            <View
                style={[styles.chatSuggestionsContainer]}
                onTouchEnd={Keyboard.dismiss}
            >
                {suggestions.map((suggestion, index) => {
                    return (
                        <Pressable 
                            key={`chat-suggestion-bubble-${index}`}
                            style={styles.chatSuggestionBubble}
                            onPress={() => {
                                HapticFeedback.lightFeedback()
                                props.sendPrompt(suggestion)
                            }}
                        >
                            <Text
                                key={`chat-suggestion-bubble-${index}`} 
                                style={styles.chatSuggestionText}
                            >
                                "{suggestion}"
                            </Text>
                        </Pressable>
                    )
                })}
            </View>
        </TouchableWithoutFeedback>
    );
}

const styles = StyleSheet.create({
    chatSuggestionsContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    chatSuggestionBubble: {
        height: 32,
        borderColor: '#f4f4f4',
        borderWidth: 1,
        borderRadius: 16,
        paddingRight: 16,
        paddingLeft: 16,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 4,
        marginBottom: 4
    },
    chatSuggestionText: {
        color: 'black',
        opacity: 0.5,
        fontWeight: "600",
        fontSize: 12,
        height: 15
    }
});

export default ChatMessageSuggestions;
