import { PropsWithChildren, useState } from "react"
import { Dimensions, Platform } from "react-native"
import { GestureType, PanGestureHandler } from "react-native-gesture-handler";

import * as HapticFeedback from '../util/HapticFeedback'

type WebSafeEdgePanGestureProps = {
    canPanFromLeftEdge: boolean
    canPanFromRightEdge: boolean
    ttsButtonGestureHandler: React.MutableRefObject<GestureType | undefined> | undefined
    didPanFromRightEdge: () => void
    didPanFromLeftEdge: () => void
}

const TARGET_VELOCITY = 200
const TARGET_TRANSLATION = 100
const TARGET_EDGE_OFFSET = 200

function WebSafeEdgePanGesture(props: PropsWithChildren<WebSafeEdgePanGestureProps>) {
    const [canPerformGesture, setCanPerformGesture] = useState(false)

    return <>{props.children}</>

    return ['web', 'android'].includes(Platform.OS) ?
        <>{props.children}</> :
        <PanGestureHandler 
            waitFor={props.ttsButtonGestureHandler}
            onGestureEvent={({nativeEvent})=> {
                const startX = nativeEvent.absoluteX - nativeEvent.translationX
                if (!canPerformGesture || Math.abs(nativeEvent.velocityX) < TARGET_VELOCITY) return
                if (props.canPanFromLeftEdge && startX < TARGET_EDGE_OFFSET && nativeEvent.translationX > TARGET_TRANSLATION) {
                    props.didPanFromLeftEdge()
                    setCanPerformGesture(false)
                    HapticFeedback.lightFeedback()
                } else if (props.canPanFromRightEdge && startX > Dimensions.get('window').width - TARGET_EDGE_OFFSET && nativeEvent.translationX < -TARGET_TRANSLATION) {
                    props.didPanFromRightEdge()
                    setCanPerformGesture(false)
                    HapticFeedback.lightFeedback()
                }
            }}
            onBegan={() => setCanPerformGesture(true)}
            onEnded={() => setCanPerformGesture(true)}
        >
            {props.children}
        </PanGestureHandler>
}

export default WebSafeEdgePanGesture