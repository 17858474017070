import {
    Linking,
    NativeModules,
    Platform
} from 'react-native';

const { RNAndroidOpenSettings } = NativeModules;

export function openAppSettings() {
    switch (Platform.OS) {
      case ('ios'):
        return Linking.openURL("app-settings:");
      case ('ios'):
        return RNAndroidOpenSettings.appDetailsSettings();
      default: return
    }
}