class WebSocketManager {
    private socketConnections: {[uuid:string]: WebSocket} = {}

    async createSocket(uuid: string, uri: string): Promise<WebSocket> {
        const ws = new WebSocket(uri, undefined);
        this.socketConnections[uuid] = ws;
        return ws
    }

    get(uuid: string): WebSocket {
        const ws = this.socketConnections[uuid]
        if (!ws) throw new Error('Socket not found')
        return ws
    }

    destroy(uuid: string) {
        if (!this.socketConnections[uuid]) return
        this.socketConnections[uuid].close()
        delete this.socketConnections[uuid];
    }
}

export default new WebSocketManager()