import { AVPlaybackStatusError, AVPlaybackStatusSuccess, Audio } from 'expo-av';
import * as UUID from '../util/UUID';
import * as TTSAPi from '../network/api/tts'
import * as FileSystem from 'expo-file-system';

export class PremiumTTSHandler {
    private bufferSize = 160
    private destroyed = false
    private consumingChunks = false

    private lock: boolean = false

    private soundObject: Audio.Sound | null = null

    private listener: ((active: boolean) => void) | null = null

    private blobs: any[] = []
    private chunks: string[] = []
    private buffer: string = ''

    async playTTS(text: string, ttsUUID: string) {
        const response = await TTSAPi.post(text)
        const blob = await response.blob()
        this.blobs.push(blob)
        this.playNextAudio()
    }

    setListener(listener: (active: boolean) => void) {
        this.listener = listener
    }

    bufferText(text: string) {
        if (this.destroyed) return
        this.buffer += ' ' + text;
        if (this.buffer.length >= this.bufferSize || /[.!?]/.test(text)) this.consumeBuffer()
    }

    endBuffer() {
        if (this.destroyed) return
        if (this.buffer.trim()) this.consumeBuffer()
    }

    consumeBuffer() {
        if (this.destroyed) return
        this.chunks.push(this.buffer)
        this.buffer = ''

        if (this.consumingChunks) return

        this.consumeChunks()
    }

    consumeChunks() {
        if (this.chunks.length === 0 || this.destroyed === true) {
            this.consumingChunks = false
            if (this.listener !== null) this.listener(false)
            return
        }

        if (this.consumingChunks === false && this.listener !== null) this.listener(true)

        this.consumingChunks = true

        const chunk = this.chunks.shift() || ''
        
        this.playTTS(chunk, UUID.getRandomUUID())
            .then(() => {
                this.consumeChunks.bind(this)()
            })
    }

    async playNextAudio() {
        if (this.lock) return
        try {
            if (this.soundObject) {
                const status = await this.soundObject.getStatusAsync()
                if ((status as AVPlaybackStatusSuccess).isPlaying) return
            }
        } catch {}
        const nextBlob = this.blobs.shift();
        if (!nextBlob) return
        this.lock = true
        try {
            const uri = FileSystem.cacheDirectory + `${UUID.getRandomUUID()}.mp3`;
            const reader = new FileReader();
            const base64 = await new Promise<string>((resolve, reject) => {
                reader.onerror = () => {
                    reader.abort();
                    reject(new Error('Problem parsing Blob data'));
                };
                reader.onload = () => {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(nextBlob);
            });
            await FileSystem.writeAsStringAsync(uri, base64.split(',')[1], {
                encoding: FileSystem.EncodingType.Base64,
            });
            this.soundObject = new Audio.Sound()
            await this.soundObject.loadAsync({uri})
            this.soundObject.setOnPlaybackStatusUpdate(async (playbackStatus: AVPlaybackStatusSuccess | AVPlaybackStatusError) => {
                if (
                    (playbackStatus as AVPlaybackStatusSuccess).didJustFinish || 
                    (playbackStatus as AVPlaybackStatusError).error
                ) {
                    await this.soundObject?.unloadAsync();
                    await FileSystem.deleteAsync(uri);
                    await this.playNextAudio.bind(this)();
                }
                });
            await this.soundObject.playAsync()
        } catch (err) {
            console.error(err)
            console.error('Failed for blob', nextBlob)
        } finally {
            this.lock = false
        }
    }

    async stopAndClear() {
        this.chunks = []
        this.buffer = ''
        await this.soundObject?.stopAsync()
        await this.soundObject?.unloadAsync()
        this.listener?.(false)
        this.listener = null
    }

    async destroy() {
        this.destroyed = true
        this.buffer = ''
        await this.soundObject?.stopAsync()
        await this.soundObject?.unloadAsync()
    }
}