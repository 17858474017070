import * as UUID from "../../util/UUID"
import { Platform } from "react-native"

//const WHISPER_URI = 'https://text-machina-api.ngrok.app/whisper'
const WHISPER_URI = 'https://text-machina-api.herokuapp.com/whisper'

export async function post(uri: string, abortController?: AbortController) {
    const deviceId = await UUID.getDeviceUUID()

    const formData = new FormData()

    if (Platform.OS === 'web') {
    
        const blob: Blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
              try {
                resolve(xhr.response);
              } catch (error) {
                console.log("error:", error);
              }
            };
            xhr.onerror = (e) => {
              console.log(e);
              reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", uri, true);
            xhr.send(null);
        });

        formData.append("model", 'whisper-1')
        formData.append("file", blob, 'recording')
    } else {
        const uriParts = uri.split('/')
        const filename = uriParts[uriParts.length - 1]
        const mimetype = filename.split('.')[1]
    
        let fileoptions: any = {
          uri,
          name: filename,
          type: `audio/${mimetype}`,
        }


        formData.append("model", 'whisper-1')
        formData.append("file", fileoptions)
    }

    const headers: any = {
      'Accept': 'application/json',
      'x-device-id': deviceId,
      'application-type': Platform.OS
    }

    const response = await fetch(WHISPER_URI, {
      method: 'POST',
      headers,
      body: formData,
      signal: abortController?.signal
    })

    const data = await response.json()

    if (!data.text) {
      throw new Error('No message recorded');
    }

    const { text: prompt } = data

    return prompt
}