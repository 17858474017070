import * as UUID from '../util/UUID';
import * as TTSAPi from '../network/api/tts'

export class PremiumWebTTSHandler {
    private bufferSize = 160
    private destroyed = false
    private consumingChunks = false

    private lock: boolean = false

    private audioElementRef: any
    private audioContextRef: any

    private listener: ((active: boolean) => void) | null = null

    private blobs: any[] = []
    private chunks: string[] = []
    private buffer: string = ''

    constructor(audioElementRef: any, audioContext: any) {
        this.audioElementRef = audioElementRef
        this.audioContextRef = audioContext
        
        this.audioElementRef.current?.addEventListener('ended', () => {
            this.playNextAudio.bind(this)()
        });
        this.audioElementRef.current?.addEventListener('canplay', () => {
            this.audioElementRef.current?.play()
        });
    }

    decodeAudioData(arrayBuffer: ArrayBuffer) {
        return new Promise((resolve, reject) => {
            this.audioContextRef.current?.decodeAudioData(
                arrayBuffer,
                (buffer: any) => resolve(buffer),
                (error: any) => reject(error)
            );
        });
    };

    playDecodedAudio(audioBuffer: any) {
        const source = this.audioContextRef.current?.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(this.audioContextRef.current?.destination);
        source.start(0);
        return source;
    };

    async playTTS(text: string, ttsUUID: string) {
        const response = await TTSAPi.post(text)
        const blob = await response.blob()
        this.blobs.push(blob)
        this.playNextAudio()
    }

    setListener(listener: (active: boolean) => void) {
        this.listener = listener
    }

    bufferText(text: string) {
        if (this.destroyed) return
        this.buffer += ' ' + text;
        if (this.buffer.length >= this.bufferSize || /[.!?]/.test(text)) this.consumeBuffer()
    }

    endBuffer() {
        if (this.destroyed) return
        if (this.buffer.trim()) this.consumeBuffer()
    }

    consumeBuffer() {
        if (this.destroyed) return
        this.chunks.push(this.buffer)
        this.buffer = ''

        if (this.consumingChunks) return

        this.consumeChunks()
    }

    consumeChunks() {
        if (this.chunks.length === 0 || this.destroyed === true) {
            this.consumingChunks = false
            if (this.listener !== null) this.listener(false)
            return
        }

        if (this.consumingChunks === false && this.listener !== null) this.listener(true)

        this.consumingChunks = true

        const chunk = this.chunks.shift() || ''
        
        this.playTTS(chunk, UUID.getRandomUUID())
            .then(() => {
                this.consumeChunks.bind(this)()
            })
    }

    async playNextAudio() {
        if (this.lock) return
        if (!this.audioElementRef.current?.paused) return
        const nextBlob = this.blobs.shift();
        if (!nextBlob) return
        this.lock = true
        try {
            const nextBlobUrl: any = URL.createObjectURL(nextBlob);
            const prevBlobUrl = this.audioElementRef.current?.src;
            if (prevBlobUrl) {
                URL.revokeObjectURL(prevBlobUrl);
            }
            if (this.audioElementRef.current)
                this.audioElementRef.current.src = nextBlobUrl;
            this.audioElementRef.current?.play().catch((error: any) => {
                console.error('Error playing audio:', error);
                // If an error occurs, try playing the next audio
                this.playNextAudio.bind(this)();
            });
        } catch (err) {
            console.error(err)
            console.error('Failed for blob', nextBlob)
        } finally {
            this.lock = false
            this.playNextAudio()
        }
    }

    async stopAndClear() {
        this.chunks = []
        this.buffer = ''
        this.audioElementRef.current?.pause()
        this.listener?.(false)
        this.listener = null
    }

    async destroy() {
        this.destroyed = true
        this.buffer = ''
        this.audioElementRef.current?.pause()
    }
}