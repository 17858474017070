import { useRef, useEffect, useState, useCallback } from 'react'
import React from 'react'
import {
  Keyboard,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  View
} from 'react-native';
import { FlashList } from "@shopify/flash-list";

// Internal Imports

import ChatMessage from './ChatMessage';
import { ChatData } from '../types/ChatData';
import { usePrevious } from '../util/CustomHooks';

const INVERTED = false

type ChatMessagesProps = {
    chatData: ChatData[]
    submittingText: boolean
    submittingVoice: boolean
    lastMessageSentAt: Date | undefined

    tapToRetry: (uuid: string) => void
    didCopyMessage: () => void
}

function ChatMessages(props: ChatMessagesProps): JSX.Element {
    const scrollViewRef = useRef<FlashList<any> | null>(null);
    const [scrollLock, setScrollLock] = useState(true)
    const [scrollDrag, setScrollDrag] = useState(false)
    const [momentumScroll, setMomentumScroll] = useState(false)
    const [lastDataLoadScroll] = useState(new Date())
    const [contentSize, setContentSize] = useState({width:0, height: 0})
    const previousContentSize = usePrevious<{height: number, width: number}>(contentSize)
    const [layout, setLayout] = useState({width:0, height: 0})
    const previousLayout = usePrevious<{height: number, width: number}>(layout)
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState<'none' | 'animated' | 'unanimated'>('unanimated')

    useEffect(() => {
        const listeners = [
            Keyboard.addListener('keyboardDidHide', () => scrollToBottom(false, contentSize, layout)),
        ]

        return () => {listeners.forEach(l => l.remove())}
    }, [contentSize, layout])
    
    useEffect(() => {
        // Check time constraints
        const timeSinceLastDataLoadScroll = lastDataLoadScroll ? new Date().getTime() - lastDataLoadScroll.getTime() : undefined
        const inLoadScrollRepeatWindow = (timeSinceLastDataLoadScroll !== undefined && timeSinceLastDataLoadScroll < 500)
        
        const contentHeightChanged = (contentSize.height - (previousContentSize ? previousContentSize.height : 0)) > 0
        const layoutHeightChanged = (layout.height - (previousLayout ? previousLayout.height : 0)) > 0
        
        if ((inLoadScrollRepeatWindow && contentHeightChanged) || layoutHeightChanged) scrollToBottom(false, contentSize, layout)
    }, [lastDataLoadScroll, contentSize, previousContentSize, layout, previousLayout])

    useEffect(() => {
        setShouldScrollToBottom('unanimated')
    }, [props.lastMessageSentAt])

    useEffect(() => {
        if (shouldScrollToBottom !== 'none') {
            const animated = shouldScrollToBottom === 'animated'
            if (contentSize.height < layout.height) return scrollToBottom(animated, contentSize, layout)
            setShouldScrollToBottom('none')
            if (scrollLock && !scrollDrag && !momentumScroll) scrollToBottom(animated, contentSize, layout)
        }
    }, [shouldScrollToBottom, scrollLock, scrollDrag, momentumScroll, contentSize, layout])

    const renderItem = useCallback((list: { item: ChatData, index: number }) => {
        return <ChatMessage 
            chatData={list.item}
            shouldHaveBottomDivider={ INVERTED ? list.index > 0 : list.index < props.chatData.length - 1 }
            didCopyMessage={props.didCopyMessage} 
            tapToRetry={props.tapToRetry}
        />
    }, [props.didCopyMessage, props.tapToRetry, props.chatData])

    const scrollToBottom = (
        animated: boolean, 
        contentSize: { height: number, width: number }, 
        layout: { height: number, width: number }
    ) => {
        INVERTED ? 
            scrollViewRef.current?.scrollToOffset({animated, offset: 0}) :
            scrollViewRef.current?.scrollToOffset({animated, offset: contentSize.height - layout.height})
    }

    const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        if (Platform.OS !== 'web' && scrollDrag && Keyboard.isVisible()) Keyboard.dismiss()

        const paddingToBottom = 100

        setScrollLock(
            INVERTED ?
                event.nativeEvent.contentOffset.y < paddingToBottom :
                event.nativeEvent.layoutMeasurement.height + event.nativeEvent.contentOffset.y >= 
                event.nativeEvent.contentSize.height - paddingToBottom
        )
    }

    const onScrollBeginDrag = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        setScrollDrag(true)
    }

    const onScrollEndDrag = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        setScrollDrag(false)
    }

    const onMomentumScrollBegin = () => {
        setMomentumScroll(true)
    }

    const onMomentumScrollEnd = () => {
        setMomentumScroll(false)
    }

    const onContentSizeChange = (width: number, height: number) => { 
        setShouldScrollToBottom('animated') 
        setContentSize({width, height}) 
    }

    const onLayout = (ev: LayoutChangeEvent) => {
        setShouldScrollToBottom('animated'); 
        setLayout({width: ev.nativeEvent.layout.width, height: ev.nativeEvent.layout.height})
    }

    return (
        <View style={{flex: 1}}>
            <View
                style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}}
            >
                <FlashList
                    data={INVERTED ? props.chatData.slice().reverse() : props.chatData}
                    ref={scrollViewRef}
                    onScroll={onScroll}
                    onScrollBeginDrag={onScrollBeginDrag}
                    onScrollEndDrag={onScrollEndDrag}
                    onMomentumScrollBegin={onMomentumScrollBegin}
                    onMomentumScrollEnd={onMomentumScrollEnd}
                    onContentSizeChange={onContentSizeChange}
                    onLayout={onLayout}
                    scrollEventThrottle={50}
                    estimatedItemSize={55}
                    inverted={INVERTED}
                    renderItem={renderItem}
                />
            </View>
        </View>
  );
}

export default ChatMessages;
