// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByOX0hys4YLv4tgqpGzkvYBb_t798cBSI",
  authDomain: "jackchat-e5d21.firebaseapp.com",
  projectId: "jackchat-e5d21",
  storageBucket: "jackchat-e5d21.appspot.com",
  messagingSenderId: "845786467570",
  appId: "1:845786467570:web:685bfb2af7892ebff4546b",
  measurementId: "G-EGE60W3SZE"
};

class WebAnalytics {
    app: any;
    analytics: any;
    logEvent: any;

    constructor() {
        this.app = initializeApp(firebaseConfig)
        this.analytics = getAnalytics(this.app);
        this.logEvent = logEvent
    }
}

export default new WebAnalytics()