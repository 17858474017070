import React from 'react'
import {
  StyleSheet,
  useColorScheme,
  View
} from 'react-native';

function VerticalDivider(props: {light: boolean, height?: number}): JSX.Element {
  return (
    <View
        style={[
            styles.verticalDiv, { backgroundColor: 'black' }, 
            props.light ? { opacity: 0.1 }: null,
            { height: props.height || 1 }
        ]}
    />
  );
}

const styles = StyleSheet.create({
    verticalDiv: {
      opacity: 0.2
    }
});

export default VerticalDivider;
